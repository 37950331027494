import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function AppContact() {
  return (
    <section id="contact" className="block contact-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Contactenos</h2>
          <div className="subtitle">Tu opinión es lo más Importante</div>
        </div>
        <Form className='contact-form' method="POST" action='correo/ejemplo.php'>
          <Row>
            <Col sm={4}>
              <Form.Control type="text" placeholder="Nombre Persona / Empresa" name='txt_nombre' required />
            </Col>
            <Col sm={4}>
              <Form.Control type="email" placeholder="Corre de contacto" name='txt_correo' required />
            </Col>
            <Col sm={4}>
              <Form.Control type="tel" placeholder="Número de Contacto" name='txt_numero' required />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Control as="textarea" placeholder="Dejanos un Comentario" name='txt_comentario' required />
            </Col>
          </Row>
          <div className='btn-holder'>
            <Button type="submit">Enviar</Button>
          </div>
        </Form>
      </Container>
  



      <Container fluid>
        <div className='contact-info'>
          <ul>
            <li>
              <i className="fas fa-envelope"></i>
              contacto@yaxkince.com.mx
            </li>
            <li>
              <i className="fas fa-phone"></i>
              Mex.(+521) editable tel
            </li>
            <li>
              <a href='https://goo.gl/maps/7aPgr6j9DsKanp9u8'><i className="fas fa-map-marker-alt"></i></a>
              <a href='https://goo.gl/maps/7aPgr6j9DsKanp9u8'>editable direccion</a>
            </li>
          </ul>
        </div>
      </Container>
    </section>
  );
}

export default AppContact;