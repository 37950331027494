import React from "react";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


import {useTranslation} from "react-i18next";




export default function Encabezado(){
    const [t, i18n] = useTranslation("global")

    return(
        <div>


<Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#home">
<h2>yaxkin ce.</h2>       
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home">{t("barramenu.Inicio")} <i className="fas fa-house-user"></i></Nav.Link>
            <Nav.Link href="#blog">{t("barramenu.Cotizar")}</Nav.Link>
            <Nav.Link href="#works">{t("barramenu.Instalaciones")}</Nav.Link>
            <Nav.Link href="#services">{t("barramenu.Servicios")}</Nav.Link>
            <Nav.Link href="#about">{t("barramenu.Nosotros")}</Nav.Link>
            <Nav.Link href="#teams">{t("barramenu.Restaurante")}</Nav.Link>            
            <Nav.Link href="#testimonials">{t("barramenu.Actividades")}</Nav.Link>
            <Nav.Link href="#contact">{t("barramenu.Contacto")}</Nav.Link>
            <Nav.Link href="https://yaxkince.com.mx/int/">{t("barramenu.Intranet")} <i class="fa-solid fa-plug"></i></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

        </div>
    )
}

